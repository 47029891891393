<template>
  <section class="time-line">
    <div class="line-block">
      <template v-for="(item, index) in approvalList" :key="index">
        <section :class="{ 'time-sec': true, 'time-trans': index }">
          <div class="time-avatar" :style="index == 0 ? 'margin-left: 74px;' : ''">
            <img class="avatar" :src="item.avatarId ? item.fullAvatar : require('@/assets/images/default-avatar.png')"
              :style="index == 0 ? 'margin-left: 0;' : ''" />
            <div class="line-box">
              <p v-if="index > 0 && index < approvalList.length - 1 && isFillCover === false" style="color:rgba(0,0,0,0.45);font-size: 14px;">或</p>
              <div class="line" :style="{borderColor:borderColors(index), display:isShow(index)}"></div>
            </div>
          </div>
        </section>
      </template>
    </div>
    <div class="line-block">
      <template v-for="(item, index) in approvalList" :key="index">
        <section :class="{ 'time-sec': true, 'time-trans': index }">
          <p class="time-name">
            <span :class="item.staffName.length > 5 ? 'text single-wrap' : ''" :title="item.staffName">{{
              item.staffName
            }}</span>（<span class="time-action"
              :style="{ color: archiveStatusObj[item.state]?.color || '#52c41A' }">{{
                item.state === null
                  ? index === approvalList.length - 1 && examineStatus === 4
                    ? '完成归档申请'
                    : index === 0
                      ? '发起申请'
                      : '发起归档申请'
                  : archiveStatusObj[item.state]?.name || '--'
              }}</span>）
          </p>
          <p class="time-create">{{ item.examineTime ? item.examineTime.split(' ')[0] : '' }}</p>
          <p class="time-create">{{ item.examineTime ? item.examineTime.split(' ')[1] : '' }}</p>
          <p class="reason double-wrap" v-if="item.state === 3 || item.state === 4 || item.state === 5"
            :title="item.opinion ? item.opinion : null">
            {{ item.opinion || '' }}
          </p>
        </section>
      </template>
    </div>
  </section>
</template>

<script>
import { approvalStatusObj, archiveStatusObj } from '@/utils/constData'
import '@/style/approval-timeline.less'
import { computed } from 'vue'
export default {
  name: 'approval-time-line-list',
  props: {
    approvalList: {
      type: Array,
      default: () => []
    },
    archivedLength: {
      default: 0
    },
    examineStatus: {
      default: 10
    },
    // 是否是补盖， false 为补盖
    isFillCover: {
      type: Boolean,
      default: true
    }
  },
  setup(props) {
    const borderColors = computed(()=>(index)=>{
      let color = '#C3161C'
      if(props.isFillCover === false){
        if (index >= 1) color = '#DCDEE0'
      }
      return color
    })
    const isShow= computed(()=>(index)=>{
      let displays='inherit'
      if(props.approvalList.length === index + 1) displays = 'none'
      return displays
    })
    return {
      borderColors,
      isShow,
      approvalStatusObj,
      archiveStatusObj
    }
  }
}
</script>

<style lang="less" scoped>

</style>
